<template>
  <div>
    <div class="gallery-grid">
      <div v-for="media in medias" :key="media._id" class="media-item">
        <div class="media-item-wrapper">
          <div class="media-item-inner-wrapper">
            <div class="media-thumb">
              <img :xlink:href="media.url" :src="media.url" />
            </div>
            <div class="media-details">
              <div class="media-name">
                <h2>
                  {{ media.key }}
                </h2>
              </div>
            </div>
            <div class="media-action">
              <b-button
                v-clipboard:copy="media.url"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                variant="warning"
                class="btn-icon"
              >
                <feather-icon icon="ClipboardIcon"></feather-icon>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-1">
      <b-pagination
        v-model="page"
        :total-rows="rows"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import { BPagination, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      page: 1,
      perPage: 20,
      medias: [],
      rows: 0,
    };
  },
  components: {
    BPagination,
    BButton,
    ToastificationContent,
  },
  watch: {
    page(newVal, old) {
      this.getMedia();
    },
  },
  methods: {
    getMedia() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gallery/get?page=${this.page}&size=${this.perPage}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.medias = response.data.gallery;
          this.rows = response.data.totalCount;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied",
          icon: "BellIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
  },
  mounted() {
    this.getMedia();
  },
};
</script>

<style>
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
}
.media-item {
  grid-column: span 3 / auto;
  max-width: 100%;
}
.media-item-wrapper {
  background: rgb(33, 33, 52);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(50, 50, 77);
  box-shadow: rgba(3, 3, 5, 0.2) 1px 1px 10px;
  height: 100%;
}
.media-thumb {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 10.25rem;
  width: 100%;
  background: repeating-conic-gradient(
      rgb(24, 24, 38) 0%,
      rgb(24, 24, 38) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center / 20px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.media-details {
  padding: 8px 12px;
}
.media-name {
  word-break: break-all;
}

.media-name h2 {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 600;
  color: rgb(255, 255, 255);
}
.media-item-inner-wrapper {
  position: relative;
}
.media-action {
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>
